<template>
  <v-row>
    <snack-bar />

    <v-col
      cols="12"
      lg="6"
      class="primary d-none d-md-flex align-center justify-center backgroundImg"
    >
      <v-container>
        <div class="pa-10">
          <v-row justify="center">
            <v-col cols="8" xl="5">
              <div class="d-flex justify-center">
                <img src="@/assets/images/logos/logo-vumetric-telus-white.svg" alt="Vumetric logo">
              </div>
              <div class="d-flex justify-center">
                <v-btn
                  class="mt-4 text-capitalize"
                  x-large
                  outlined
                  color="white"
                  @click="goToCorporateWebsite"
                >
                  {{ $t("learnMore") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
    <v-col cols="12" lg="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row>
            <v-col v-if="pinID !== null && transferToken === null" cols="12">
              <h2 class="font-weight-bold mt-4">
                {{ $t("twoFASecurity") }}
              </h2>
              <h6 class="text-subtitle-1">
                {{ $t("communication.enterPinCode") }}
              </h6>

              <v-form ref="formOTP" v-model="validOTP">
                <v-otp-input
                  v-model="otp"
                  :rules="[(v) => !!v || $t('validation.fieldRequired')]"
                  label="PIN"
                  class="mt-4"
                  autofocus
                  required
                  outlined
                  @finish="validatePin"
                />
                <v-btn
                  :disabled="!validOTP"
                  depressed
                  color="primary mt-6"
                  block
                  class="mr-4"
                  @click="validatePin(otp)"
                >
                  {{ $t("send") }}
                </v-btn>
              </v-form>
            </v-col>
            <v-col cols="12" v-if="transferToken !== null">
              <v-row class="text-center">
                <v-col cols="12">
                  <img
                    src="@/assets/images/svg/startup.svg"
                    class="my-3"
                    contain
                    height="200"
                  />
                </v-col>
                <v-col class="mb-4">
                  <h1 class="text-h3 font-weight-bold mb-3">{{ $t("docReady") }}</h1>

                  <p class="subheading font-weight-regular">
                    {{ $t("communication.oneTimeDownloadLink") }}
                    <v-template v-for="(item, i) in files" :key="i">
                      <v-btn
                        :ripple="false"
                        height="72"
                        class="mt-10"
                        min-width="164"
                        @click="downloadReportFile(item)"
                      >
                        <v-icon
                          large
                          color="primary"
                          class="text-36 mr-2 d-none d-sm-block"
                        >mdi-download</v-icon>
                        {{ $t("download") }}
                      </v-btn>
                    </v-template>
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import AuthService from '@/services/auth.service'
import FilesService from '@/services/files.service'
import { docTypes, selectIconFileType } from '@/data/common'
import snackbarPlugin from '@/plugins/snackbar'
import SnackBar from '@/components/base/SnackBar.vue'

export default {
  name: 'Communication',
  components: { SnackBar },
  data() {
    return {
      communicationToken: this.$route.params.token,
      pinID: null,
      transferToken: null,
      files: [],
      validOTP: false,
      otp: '',
      docTypes,
      selectIconFileType,
      headers: [
        {
          text: this.$t("fileName"),
          value: 'name',
          class: 'text-left font-weight-medium subtitle-1'
        },
        // {
        //   text: 'Type',
        //   value: 'type',
        //   align: 'center',
        //   class: 'text-left font-weight-medium subtitle-1'
        // },
        {
          text: this.$t("date"),
          width: '125px',
          value: 'createdAt',
          align: 'center',
          class: 'text-left font-weight-medium subtitle-1'
        },
        {
          text: this.$t("actions"),
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '180px',
          class: 'text-center font-weight-medium subtitle-1'
        }
      ]
    }
  },
  beforeCreate() {
    const language = this.$route.query.lang
    if (language && language !== this.$i18n.locale) {
      if (this.$i18n.availableLocales.indexOf(language) !== -1) {
        this.$i18n.locale = language
        this.$vuetify.lang.current = language
      }
    }
  },
  mounted() {
    AuthService.checkCommunicationToken(
      this.communicationToken
    ).then((resp) => {
      this.pinID = resp.id
    }).catch((err) => {
      if ("response" in err) {
        if (err.response.data.detail) {
          snackbarPlugin.showError(err.response.data.detail)
          console.log(err.response.data.detail)
        }
      }
      // this.$router.push('/login')
    })
  },
  methods: {
    goToCorporateWebsite() {
      window.location.href = ('https://www.vumetric.com')
    },
    async validatePin(pinValue) {
      AuthService.validateCommunicationPin(
        { id: this.pinID, pin: pinValue },
        this.communicationToken
      ).then((resp) => {
        this.transferToken = resp.transferToken
        this.files = resp.files
      }).catch((err) => {
        if (err.response.data.detail) {
          snackbarPlugin.showError(err.response.data.detail)
        }
      })
    },
    async downloadReportFile(file) {
      FilesService.getFileDownloadFromCommunication(
        file.id, file.name, file.type, this.transferToken
      ).then(() => {
        this.$swal({
          text: this.$t("messages.downloadStarted"),
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: this.$t("messages.okGotIt"),
          customClass: {
            confirmButton: 'btn fw-bold btn-light-primary'
          }
        })
      }).catch((err) => {
        if (err.response.data.detail) {
          snackbarPlugin.showError(err.response.data.detail)
        }
        this.$router.push('/login')
      })
    }
  }
}
</script>
<style lang="scss">

</style>
